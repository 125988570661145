<template>
  <div>
    <vue-good-table
      class="position-relative"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'bulk-selection-wrapper',
        selectionText: 'Participants selected',
        clearSelectionText: 'Clear Selections',
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      sort-options="{
        enabled: true,
        initialSortBy: {field: 'dateAdded', type: 'asc'}
    }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="onBulkSelectionChange"
    >
      <template 
        slot="table-row" 
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="STATUS_COLOR[props.row.status]">
            {{ props.row.status }}
          </b-badge>
        </span>
        <div
          v-else-if="props.column.field === 'full_name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <router-link
            :to="{
              name: 'admin-client-participant',
              params: { clientId: clientId, userId: props.row.id },
            }"
          >
            {{ props.row.full_name }}
          </router-link>
        </div>
        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="d-flex align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="btn-icon"
              :to="{
                name: 'admin-client-participant',
                params: { clientId: clientId, userId: props.row.id },
              }"
            >
              <feather-icon
                icon="EyeIcon"
                size="20" 
              />
            </b-button>
            <b-button 
              variant="outline-none" 
              class="nav action-menu"
            >
              <b-nav-item-dropdown class="btn-icon">
                <template #button-content>
                  <feather-icon 
                    icon="MoreVerticalIcon" 
                    size="20"
                  />
                </template>
                <!-- Suspend User -->
                <suspend-user
                  :user="props.row"
                  :user-id="props.row.id"
                  is-client-user
                  @updated="loadItems"
                />
                <remove-user
                  :user="props.row"
                  :user-id="props.row.id"
                  is-client-user
                  @updated="loadItems"
                />
              </b-nav-item-dropdown>
            </b-button>
          </div>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template 
        slot="pagination-bottom" 
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
      <div slot="selected-row-actions">
        <b-dropdown 
          text="Selected Actions"
          variant="primary"
        >
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="onClickBulkSuspend"
          >
            <feather-icon 
              size="16" 
              icon="UserMinusIcon"
              class="mr-50" 
            />
            <span>Suspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="onClickBulkUnsuspend"
          >
            <feather-icon 
              size="16"
              icon="UserCheckIcon" 
              class="mr-50" 
            />
            <span>Unsuspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="onClickBulkRemove"
          >
            <feather-icon 
              size="16" 
              icon="TrashIcon" 
              class="mr-50" 
            />
            <span>Remove</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </vue-good-table>
    <aom-delete-confirmation-modal ref="delete-confirmation-modal" />
  </div>
</template>

<script>
import AomDeleteConfirmationModal from "@aom-core/AomDeleteConfirmationModal.vue";
import {
  BBadge,
  BButton,
  BNavItemDropdown,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import {
  STATUS_COLOR,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  makeErrorToast,
  makeSuccessToast,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import store from "@/store/index";
import {
  userStatus,
  userStatusDisplay,
  userStatusSelect,
  participantRolesDisplay,
  userRoles as userRolesModel,
  userRolesDisplay,
} from "@models";
import participantsService from "@/services/participantsService";
import SuspendUser from "@/views/apps/participants/actions/SuspendUser.vue";
import RemoveUser from "@/views/apps/participants/actions/RemoveUser";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import usersService  from "@/services/usersService";

export default {
  name: "ParticipantsListForClient",
  components: {
    VueGoodTable,
    BBadge,
    TablePagination,
    SuspendUser,
    RemoveUser,
    BButton,
    BNavItemDropdown,
    SuspendUser,
    RemoveUser,
    BDropdown,
    BDropdownItem,
    AomDeleteConfirmationModal,
  },
  data() {
    return {
      isLoading: false,
      dir: false,
      columns: [
        {
          label: "Full Name",
          field: "full_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
          width: "10em",
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
          width: "10em",
        },
        {
          label: "User Role",
          field: "role",
          filterOptions: {
            enabled: true,
            placeholder: "Search Roles",
            filterDropdownItems: participantRolesDisplay,
          },
          width: "10em",
        },
        {
          label: "Date Added",
          field: "created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: userStatusSelect,
          },
          width: "6em",
        },
        {
          field: "action",
          width: "6em",
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      bulkSelection: [],
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    clientId() {
      return Number(this.$route.params.clientId);
    },
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    const elements = document.getElementsByName("vgt-created_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    onBulkSelectionChange(args) {
      const { selectedRows } = args;
      if (Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
      }
    },
    async onClickBulkRemove() {
      const isConfirmed = await this.$refs['delete-confirmation-modal'].confirmDelete(
        'Warning: Removing these users will remove access from all programs. Are you sure you want to continue? Users who have a locked or active match will not be removed.',
        {
          title: `Remove ${this.bulkSelection.length} users`,
          okTitle: 'Remove'
        }
      );

      if (!isConfirmed) {
        return ;
      }

      try {
        const userIds = this.bulkSelection
          .filter(u => u.locked_and_active_matches_count === 0)
          .map(u => {
            return { id: u.id };
          });

        if (userIds.length === 0) {
          this.$toast(
            makeErrorToast(
              "Users have locked or active matches and cannot be deleted."
            )
          );
        }
        
        const data = {
          users: userIds,
        };
        const result = await usersService.deleteClientParticipantMany(
          this.clientId,
          data
        );
        if (result) {
          this.$toast(makeSuccessToast("Bulk delete successful."));
          this.loadItems();
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }

    },
    async onClickBulkSuspend() {
      if (
        !Array.isArray(this.bulkSelection) ||
        this.bulkSelection.length === 0
      ) {
        return;
      }

      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to suspend these users? Users who have a locked or active match will not be suspended.",
        {
          title: `Suspend ${this.bulkSelection.length} users`,
          size: "sm",
          okVariant: "primary",
          okTitle: "Suspend",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!isConfirmed) {
        return;
      }

      try {
        const userIds = this.bulkSelection
          .filter(u => u.locked_and_active_matches_count === 0)
          .map(u => {
            return { id: u.id };
          });

        if (userIds.length === 0) {
          this.$toast(
            makeErrorToast(
              "Users have locked or active matches and cannot be suspended."
            )
          );
        }

        const data = {
          status_id: userStatus.SUSPENDED,
          users: userIds,
        };
        const result = await usersService.setClientParticipantStatusMany(
          this.clientId,
          data
        );
        if (result) {
          this.$toast(makeSuccessToast("Bulk suspend successful."));
          this.loadItems();
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
    async onClickBulkUnsuspend() {
      if (
        !Array.isArray(this.bulkSelection) ||
        this.bulkSelection.length === 0
      ) {
        return;
      }

      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to unsuspend these users?",
        {
          title: `Unsuspend ${this.bulkSelection.length} users`,
          size: "sm",
          okVariant: "primary",
          okTitle: "Unsuspend",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!isConfirmed) {
        return;
      }

      try {
        const userIds = this.bulkSelection.map(u => {
          return { id: u.id };
        });
        const data = {
          status_id: userStatus.ACTIVE,
          users: userIds,
        };
        const result = await usersService.setClientParticipantStatusMany(
          this.clientId,
          data
        );
        if (result) {
          this.$toast(makeSuccessToast("Bulk unsuspend successful."));
          this.loadItems();
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== "none") {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "status",
        "full_name",
        "email",
        "role",
        "program",
        "created_at",
      ]) {
        if (params.columnFilters[col]) {
          if (col === "role") {
            let roleIds = [];
            roleIds.push(params.columnFilters.role);
            columnFilters.push({
              field: col,
              value: roleIds,
            });
          } else if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await participantsService.getParticipantsByClient(
          this.$route.params.clientId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          avatar: "",
          created_at: item.created_at,
          email: item.email,
          full_name: item.full_name,
          id: item.id,
          status: userStatusDisplay[item.user_roles[0].status_id],
          role: this.userRole(item?.user_roles),
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Participants list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    userRole(userRoles) {
      if (userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];
        // remove PARTICIPANT CANDIDATE role if MENTOR/MENTEE role is existed
        const hasParticipantRoles = uniqueRoles.findIndex(
          r =>
            r === userRolesDisplay[userRolesModel.MENTOR] ||
            userRolesDisplay[userRolesModel.MENTEE]
        );
        const hasParticipantCandidateRole = uniqueRoles.findIndex(
          r => r === userRolesDisplay[userRolesModel.PARTICIPANT_CANDIDATE]
        );
        if (hasParticipantRoles !== -1 && hasParticipantCandidateRole !== -1) {
          uniqueRoles.splice(hasParticipantCandidateRole, 1);
        }
        return uniqueRoles.join(", ");
      }
      return "";
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      total,
      perPage,
      page,
    };
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss" >
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
.badge {
  font-weight: 400 !important;
}
.vgt-left-align {
  vertical-align: middle !important;
}

@import "/src/assets/scss/vue-good-table.scss";
.bulk-selection-wrapper {
  background: none !important;
  font-size: 14px;
  color: inherit !important;
}
::v-deep .vgt-selection-info-row {
  background: none;
  font-size: 14px;
  color: inherit;
}
</style>
